const NewPage = () => import('@/pages/new/NewPage')
const QuickStartPage = () => import('@/pages/quick-start/QuickStartPage')
const DataStreamsPage = () => import('@/pages/data-streams/DataStreamsPage')
const AccessRulesPage = () => import('@/pages/access-rules/AccessRulesPage')
const Error = () => import('@/pages/error/Error')
export default [
  { path: '/error/:error', name: 'error', component: Error, props: true},
  { path: '/new', name: 'newPage', component: NewPage , props: true},
  { path: '/data-streams', name: 'dataStreamsPage', component: DataStreamsPage, props: true },
  { path: '/quick-start', name: 'quickStartPage', component: QuickStartPage, props: true },
  { path: '/access-rules', name: 'accessRulesPage', component: AccessRulesPage, props: true }
]
